import React from 'react';
import { Telephone, Envelope } from 'react-bootstrap-icons';
import AppStore from '../../stores/AppStore';

function Footer() {
    let { company, lang } = AppStore;

    return (
        <footer className="footer">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginLeft: '3em' }}>
                    <div className="footer-text">
                        <p>&copy; {new Date().getFullYear()} {company.CompanyNameShort}</p>
                    </div>
                    <div className="footer-text footer-link" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <p style={{ minWidth: '250px' }}>
                            <a href={`mailto:${company.CompanyEMail}`}>
                                <Envelope /> {company.CompanyEMail}
                            </a>
                            <br />
                            <a href={`tel:${company.CompanyPhone}`}>
                                <Telephone /> {company.CompanyPhone}
                            </a>
                        </p>
                        <p>
                            {company.CompanyAdressLine1},<br />
                            {company.CompanyAdressLine2}
                        </p>
                    </div>
                </div>
                <div>
                    {lang === 'ru' ? (
                        <p className=" footer-link text-center text-muted small">
                            Сайт разработан в <a href="https://tias.pro">ООО &laquo;ТИАС&raquo;</a>
                        </p>
                    ) : (
                        <p className="footer-link text-center text-muted small">
                            Made by <a href="https://tias.pro">TIAS</a>
                        </p>
                    )}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
