import React from 'react';
import { Link } from 'react-router-dom';

function LeftMenu(props) {
    let ret = (
        <div className={'left-menu'}>
            <h2>{props.section.name}</h2>
            <ul>
                {props.section.pages
                    .filter((p) => p.noshow !== 1)
                    .map((page) => {
                        return props.currentPage.name === page.name ? (
                            <li key={page.route}>
                                <span>{page.name}</span>
                            </li>
                        ) : (
                            <li key={page.route}>
                                <Link to={`/${props.section.route}/${page.route}`} className="left-menu-link">
                                    {page.name}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
    return ret;
}

export default LeftMenu;
