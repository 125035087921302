import React, { useState, useCallback } from 'react';
import DeckGL from '@deck.gl/react';
import { HexagonLayer } from '@deck.gl/aggregation-layers';
//import { HexagonLayer, LineLayer } from '@deck.gl/layers';

import { LinearInterpolator } from '@deck.gl/core';

import StaticMap from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

import { projects } from './ProjectsList';

function GeoProjects() {
    //prettier-ignore
    const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic29lbmVyZ28tcGx1cy1kZXYiLCJhIjoiY2tzcm9naGFxMG9yNTJvbzZ5ZGpzd2E4ZCJ9.tCv8KcfcjEwVxqng8tjYAA';

    const rotateCamera = useCallback(() => {
        setState(state => ({
            ...state,
            bearing: state.bearing + 5,
            transitionDuration: 1000,
            transitionInterpolator: new LinearInterpolator(['bearing']),
            onTransitionEnd: rotateCamera,
        }));
    }, []);

    const [state, setState] = useState({
        longitude: 38.673353,
        latitude: 55.322978,
        zoom: 6,
        pitch: 45,
        bearing: 0,
    });

    const [hoverInfo, setHoverInfo] = useState('');

    let layers = [
        new HexagonLayer({
            id: 'hexagon-layer',
            data: projects,
            getPosition: d => d.geo,
            pickable: true,
            autoHighlight: true,
            extruded: true,
            elevationRange: [1, 10000],
            elevationScale: 3,
            coverage: 0.95,
            radius: 50000,
            colorRange: [
                [179, 229, 221],
                [169, 216, 209],
                [159, 204, 197],
                [121, 154, 149],
                [141, 180, 174],
                [96, 153, 144],
            ],
        }),
    ];

    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', height: '80vh'}}>


            <DeckGL
                viewState={state}
                onViewStateChange={e => setState(e.viewState)}
                controller={true}
                layers={layers}
                onHover={setHoverInfo}
                onLoad={rotateCamera}
                //useDevicePixels={1}
                >
                <StaticMap
                    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                    mapStyle={'mapbox://styles/soenergo-plus-dev/ckss7ehpc2wse18quo4cmwkkd'}
                />
                {!!hoverInfo && !!hoverInfo.object && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            pointerEvents: 'none',
                            left: hoverInfo.x,
                            top: hoverInfo.y,
                            background: '#333333',
                            padding: '0.8rem',
                        }}>
                        {hoverInfo.object.points.map(p => (
                            <p key={p.source.name} style={{ marginBottom: '0.3rem', color: '#f2f2f2' }}>
                                {p.source.name}
                            </p>
                        ))}
                    </div>
                )}
            </DeckGL>
            
        </div>
        </>
    );
}

export default GeoProjects;
