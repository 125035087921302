import React from 'react';
import AppStore from '../../stores/AppStore';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { observer } from 'mobx-react';

const ChangeLang = observer((props) => {
    let { lang, changeLang } = AppStore;

    return (
        <>
            <ToggleButtonGroup type='radio' value={lang} onChange={changeLang} name='switch'>
                <ToggleButton className={'shadow-none'} value={'ru'}>
                    <span style={{ letterSpacing: '.06em' }}>РУ</span>
                </ToggleButton>
                <ToggleButton className={'shadow-none'} value={'en'}>
                    <span style={{ letterSpacing: '.06em' }}>EN</span>
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
});

export default ChangeLang;
