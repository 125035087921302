import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppStore from '../../stores/AppStore';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function ErrorPage() {
    const history = useHistory();
    const { companyName } = AppStore;

    function goBack(e) {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
            <Helmet>
                <title>{`404 — ${companyName}`}</title>
            </Helmet>
            <div
                className="d-flex align-items-center flex-column justify-content-center h-100 page-wrapper"
                id="header">
                <div className={'top-gradient'}></div>
                <div className={'bottom-gradient'}></div>
                <h1 className="error-text">Ошибка 404</h1>
                <p className="error-text">
                    <Link onClick={goBack} className="error-link">
                        &larr;&thinsp;Назад
                    </Link>{' '}
                    &middot;{' '}
                    <Link to={'/'} className="error-link">
                        На главную
                    </Link>
                </p>
            </div>
        </>
    );
}

export default observer(ErrorPage);
