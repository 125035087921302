import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Form } from 'react-bootstrap';
import Personal from './Personal';
import AppStore from '../../stores/AppStore';

import { apiRequest } from '../../deepApi'

import ym from 'react-yandex-metrika';

function AskQuestion() {
    let { lang } = AppStore;

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(undefined);
    const [validated, setValidated] = useState(false);

    function handleClose() {
        setIsShown(false);
    }

    function handleShow() {
        setIsShown(true);
    }

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            handleSend();
        }

        setValidated(true);
    };

    function handleSend() {
        var req = {
            ServiceType: "Вопрос",
            ServiceCategory: "",
            PreOrderComment: comment,
            CustomerName: name,
            CustomerCompany: "",
            CustomerEMail: email,
            CustomerTelephone: phone
        };
        setIsLoading(true);
        apiRequest(req, "api-cms-preorder-set", requestCallback);
        //ym('reachGoal','ses-mon-click');
    }

    function requestCallback(res) {
        // Добавить проверку на код ответа
        if (res.StatusID >= 0) {
            setIsSuccess(true);
        }
        else {
            alert('Произошла ошибка');
        }
        setIsLoading(false);
    }

    const action = 'Отправить';
    const required = lang === 'ru' ? 'Обязательное поле' : 'Required';

    return <>
        <Button variant="primary" className="menu-outline" onClick={handleShow}>{ lang === 'ru' ? 'Задать вопрос' : 'Ask a question'}</Button>

        <Modal show={isShown} onHide={handleClose} centered>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{lang === 'ru' ? 'Задать вопрос' : 'Ask a question'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!isSuccess && <Alert variant='success'>{lang === 'ru' ? `Спасибо за обращение, ${name}!` : `Thank your for contacting, ${name}!`}</Alert>}

                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Как к вам обращаться' : 'Your name'}</Form.Label>
                        <Form.Control type="text" className="form-control" value={name}
                            onChange={e => setName(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Телефон' : 'Phone'}</Form.Label>
                        <Form.Control type="phone" className="form-control" value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Почта' : 'E-mail'}</Form.Label>
                        <Form.Control type="text" className="form-control" value={email}
                            onChange={e => setEmail(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{lang === 'ru' ? 'Ваш вопрос' : 'Your question'}</Form.Label>
                        <Form.Control as="textarea" rows="5" className="form-control" value={comment}
                            onChange={e => setComment(e.target.value)}
                            disabled={isSuccess}
                            required />
                        <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                    </Form.Group>
                    <Personal action={action} />
                </Modal.Body>
                <Modal.Footer>
                    {isLoading ? <Spinner animation="border" size="sm" /> : null}
                    <Button variant="primary" type="submit" disabled={isLoading || isSuccess}>{action}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default AskQuestion;