import React from 'react';
import { Link } from 'react-router-dom';

function UnderConstruction(props) {
    return (
        <div className="d-flex align-items-center flex-column justify-content-center h-100 page-wrapper" id="header">
            <div className={'top-gradient'}></div>
            <div className={'bottom-gradient'}></div>
            <div className={'logo-works'}>
                <img src="../images/logo.png" width="70%" alt="" />
            </div>
            <h1 className="error-text" style={{ textAlign: 'center', maxWidth: '400px', margin: '1rem 0' }}>
                На сайте проводятся технические работы
            </h1>
            <p className="error-text">
                ООО «СоЭнерго+»
                <br />
                <a href="https://yandex.ru/maps/-/CCUEvOvDkD" target="_blank" rel="noopener noreferrer">
                    119619, г.Москва, 6-я улица Новые сады, д.2
                </a>
                <br />
                <a href="mailto:mail@soenergo.ru">mail@soenergo.ru</a>
                <br />
                <a href="tel:+7 495 785-15-91">+7 495 785-15-91</a>
                <br />
            </p>
        </div>
    );
}

export default UnderConstruction;
