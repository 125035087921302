import { configure, makeAutoObservable, toJS } from 'mobx';
import { apiRequest } from '../deepApi';

class AppStore {
  lang = 'ru';
  honeycombShown = false;
  cardsShown = false;
  advShown = false;
  company = {};
  menu = [];
  menuLoaded = false;
  hasError = false;
  vacanciesSE = [];
  // vacanciesSES = [];
  // vacanciesFG = [];

  get companyName() {
    return this.lang === 'ru' ? 'СоЭнерго+' : 'SoEnergo+';
  }

  constructor(preloadedState) {
    if (preloadedState) {
      this.menu = preloadedState.menu;
      this.menuLoaded = preloadedState.menuLoaded;
    }

    makeAutoObservable(this);

    if (localStorage.getItem('lang') === 'en') {
      this.lang = 'en';
    }
    if (localStorage.getItem('lang') === 'ru') {
      this.lang = 'ru';
    }
    const req = { Lang: this.lang };
    apiRequest(
      req,
      'api-cms-company-get',
      (res) => {
        this.company = res;
      },
      (err) => (this.hasError = true)
    );
    this.getVacanciesSE();
    //this.getVacanciesSES();
    //this.getVacanciesFG();
    this.getMenu();

    if (process.env.NODE_ENV === 'development') {
      window.app = this;
    }
  }

  getMenu = () => {
    const req = { Lang: this.lang };
    apiRequest(req, 'api-cms-menu-get', (res) => {
      let apiMenu = res.MenuSections;
      apiMenu = apiMenu.filter((x) => x.index !== 0).concat(apiMenu.filter((x) => x.index === 0));
      apiMenu.push({
        name: '',
        route: '',
        pages: [
          {
            name: this.lang === 'en' ? 'Contacts' : 'Контакты',
            route: 'contacts',
          },
        ],
      });
      this.menu = apiMenu;
      this.menuLoaded = true;
    });
  };

  //4005417
  getVacanciesSE = () => {
    const req = { CompanyID: '4005417'};
    apiRequest(req, 'api-hh-get', (res) => {
      this.vacanciesSE = res.Vacancies;
    });
  };

  // getVacanciesSES = () => {
  //   const req = { CompanyID: '4445984'};
  //   apiRequest(req, 'api-hh-get', (res) => {
  //     this.vacanciesSES = res.Vacancies;
  //   });
  // };

  // getVacanciesFG = () => {
  //   const req = { CompanyID: '4140582'};
  //   apiRequest(req, 'api-hh-get', (res) => {
  //     this.vacanciesFG = res.Vacancies;
  //   });
  // };

  changeLang = () => {
    if (this.lang === 'ru') {
      this.lang = 'en';
    } else if (this.lang === 'en') {
      this.lang = 'ru';
    }
    localStorage.setItem('lang', this.lang);
    const req = { Lang: this.lang };
    apiRequest(req, 'api-cms-company-get', (res) => {
      this.company = res;
    });
    this.getMenu();
  };

  setHoneycombShown = () => {
    this.honeycombShown = true;
  };

  setCardsShown = () => {
    this.cardsShown = true;
  };

  setAdvShown = () => {
    this.advShown = true;
  };

  pages = [];
  getPage = (id, callback) => {
    const req = {
      ID: id,
      Lang: this.lang,
    };

    const page = this.pages.find((p) => p.id === id && p.lang === this.lang);
    if (page) {
      return callback(page);
    } else {
      apiRequest(req, 'api-cms-page-get', (res) => {
        const page = {
          res,
          id: id,
          lang: this.lang,
        };
        this.pages.push(page);
        return callback(page);
      });
    }
  };
}

configure({ enforceActions: 'never' });

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = new AppStore(preloadedState);

window.snapSaveState = () => ({
  __PRELOADED_STATE__: toJS(store)
});

export default store;
