import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation } from 'react-router-dom';
import { Telephone } from 'react-bootstrap-icons';
import ChangeLang from './ChangeLang';
import { observer } from 'mobx-react';
import AppStore from '../../stores/AppStore';
import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import AskQuestion from '../modal/AskQuestion';
import Callback from '../modal/Callback';

const Menu = observer((props) => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    let { setHoneycombShown, company } = AppStore;

    useEffect(() => {
        if (location.pathname !== '/') {
            setHoneycombShown();
        }
    }, [location, setHoneycombShown]);

    function collapse() {
        setExpanded(false);
    }

    function toogle() {
        setExpanded(!expanded);
    }

    return (
        <Navbar bg="primary" variant="dark" expand="lg" fixed="top" expanded={expanded} onToggle={() => {}} style={{zIndex: '1049'}}>
            {/* see https://github.com/react-bootstrap/react-bootstrap/issues/3141 */}
            <Navbar.Brand className={'fade-menu'}>
                {location.pathname !== '/' ? (
                    <Link to="/">
                        <img src={'../../images/logo.png'} className={'menu-logo '} height="30px" alt="На главную" />
                    </Link>
                ) : (
                    <img src={'../../images/logo.png'} height="30px" alt="СоЭнерго+" />
                )}
            </Navbar.Brand>

            <Navbar.Toggle onClick={() => toogle()} />
            <Navbar.Collapse id="responsive-navbar-nav" className={'fade-menu'}>
                <Nav className="mr-auto">
                    {props.sections.map((section, i) => {
                        return section.name === '' ? (
                            section.pages.map((page, j) => (
                                <Nav.Link as={Link} to={`/${page.route}/`} onClick={() => collapse()} key={j} bsPrefix="nav-link menu-link">
                                    {page.name}
                                </Nav.Link>
                            ))
                        ) : (
                            <NavDropdown title={section.name} id="basic-nav-dropdown" key={i} bsPrefix="nav-link menu-link">
                                {section.pages
                                    .filter((p) => p.noshow !== 1)
                                    .map((page, k) => (
                                        <NavDropdown.Item as={Link} to={'/' + section.route + '/' + page.route + '/'} key={k} onClick={() => collapse()}>
                                            {page.name}
                                        </NavDropdown.Item>
                                    ))}
                            </NavDropdown>
                        );
                    })}
                </Nav>
                <Nav>
                    <Nav.Link href={`tel:${company.CompanyPhone}`} bsPrefix="phone nav-link phone-link d-lg-none d-xl-block">
                        <Telephone /> {company.CompanyPhone}
                    </Nav.Link>
                </Nav>
                <Nav>
                    <ButtonToolbar>
                        <ButtonGroup style={{ marginRight: '.8em' }}>
                            <Callback />
                            <AskQuestion />
                        </ButtonGroup>
                        <ChangeLang />
                    </ButtonToolbar>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
});

export default Menu;
