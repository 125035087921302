import React, { memo, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import AppStore from '../../stores/AppStore';
import { observer } from 'mobx-react';

const MapChart = observer(({ setTooltipContent }) => {
    const [popoverContent, setPopoverContent] = useState({});
    const { lang } = AppStore;

    const geoUrl = '../russia_mercator.json';

    const popover = !!popoverContent.name && (
        <Popover id="popover-basic">
            <Popover.Title as="h3">{popoverContent.location}</Popover.Title>
            <Popover.Content dangerouslySetInnerHTML={{ __html: `<p>${popoverContent.name.join('<br/>')}</p>` }} />
        </Popover>
    );

    const markers = [
        { y: 3, x: 5, anchor: 'begin', name: 'ОАО «Щуровский цемент»', location: 'Московская обл., г. Коломна', cityShort: 'Коломна', cityShortEn: 'Kolomna', geo: [38.752917, 55.103152] },
        { y: -3, x: 3, anchor: 'begin', name: 'Офисно-торговый комплекс ЗАО «TEMA»', location: 'г. Москва', cityShort: 'Москва', cityShortEn: 'Moscow', geo: [37.622504, 55.753215] },
        { y: -1, x: -3, anchor: 'end', name: 'ООО «Фрито Лей Мануфактуринг»', location: 'Московская обл., г. Кашира', cityShort: 'Кашира', cityShortEn: 'Kashira', geo: [38.15154, 54.834589] },
        { y: -5, x: 3, anchor: 'begin', name: 'ЗАО «ЛОГИКА»', location: 'г. Москва, Зеленоград', cityShort: 'Зеленоград', cityShortEn: 'Zelenograd', geo: [37.214382, 55.991893] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «ФЕРРОМОНТ РАША»', location: 'Тверская обл., г. Торжок', cityShort: 'Торжок', cityShortEn: 'Torzhok', geo: [34.96014, 57.041338] },
        { y: -3, x: -3, anchor: 'end', name: 'ОАО «Каменская БКФ»', location: 'Тверская обл., Кувшиново', cityShort: 'Кувшиново', cityShortEn: 'Kuvshinovo', geo: [34.165877, 57.028957] },
        { y: 3, x: 5, anchor: 'begin', name: 'ООО «Холсим (Рус)»', location: 'Московская обл., г. Коломна', cityShort: 'Коломна', cityShortEn: 'Kolomna', geo: [38.752917, 55.103152] },
        { y: 3, x: -3, anchor: 'end', name: 'Деревообрабатывающий комплекс «POLINOM» ООО «Строительный Холдинг Славянский»,', location: 'Московская обл., г. Бронницы', cityShort: 'Бронницы', cityShortEn: 'Bronnitsy', geo: [38.264027, 55.425399] },
        { y: 0, x: 5, anchor: 'begin', name: 'ОАО «Лафарж Цемент» («Воскресенскцемент»)', location: 'Московская обл., г. Воскресенск', cityShort: 'Воскресенск', cityShortEn: 'Voskresensk', geo: [38.673353, 55.322978] },
        { y: -3, x: -3, anchor: 'end', name: '«PEPSICO Holding»', location: 'Московская обл., г. Кашира', cityShort: 'Кашира', cityShortEn: 'Kashira', geo: [38.15154, 54.834589] },
        { y: -3, x: -3, anchor: 'end', name: 'ООО «Нестле Россия»', location: 'Калужская область, Боровский район, с. Ворсино', cityShort: 'Ворсино', cityShortEn: 'Vorsino', geo: [36.644059, 55.225769] },
        { y: -4, x: 3, anchor: 'begin', name: 'Филиал «ВМЗ «Салют ФГУП «НПЦ газотурбостроения «Салют»', location: 'Московская обл., п. Белоозерский', cityShort: 'Белоозерский', cityShortEn: 'Beloozyorsky', geo: [38.43892, 55.459619] },
        { y: 0, x: 5, anchor: 'begin', name: 'ОАО «Воскресенские минеральные удобрения»', location: 'Московская обл., г. Воскресенск', cityShort: 'Воскресенск', cityShortEn: 'Voskresensk', geo: [38.673353, 55.322978] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Каргилл»', location: 'Тульская обл., г. Ефремов', cityShort: 'Ефремов', cityShortEn: 'Efremov', geo: [38.117673, 53.137598] },
        { y: 3, x: -5, anchor: 'end', name: 'Филиал ООО «ЭсСиЭй Хайджин Продактс Раша»', location: 'Тульская обл., г. Советск', cityShort: 'Советск', cityShortEn: 'Sovetsk', geo: [37.626636, 53.93294] },
        { y: -1, x: 3, anchor: 'begin', name: 'Пивоваренная компания «Балтика». Филиал «Балтика-Тула»', location: 'Тульская обл., г. Тула', cityShort: 'Тула', cityShortEn: 'Tula', geo: [37.617348, 54.193122] },
        { y: -3, x: -3, anchor: 'end', name: 'Краснодарское ТТУ', location: 'г. Краснодар', cityShort: 'Краснодар', cityShortEn: 'Krasnodar', geo: [38.975313, 45.03547] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Нестле Кубань»', location: 'Краснодарский край, г. Тимашевск', cityShort: 'Тимашевск', cityShortEn: 'Timashyovsk', geo: [38.933964, 45.6142] },
        { y: -3, x: 3, anchor: 'begin', name: 'ОАО «Каспийский Завод Листового Стекла» (ОАО «КЗЛС»)', location: 'Республика Дагестан, Кумторкалинский район, п. Тюбе', cityShort: 'Тюбе', cityShortEn: 'Tyube', geo: [47.292895, 43.051706] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Цемент» группы компаний ЛСР', location: 'Ленинградская обл., г. Сланцы', cityShort: 'Сланцы', cityShortEn: 'Slantsy', geo: [28.088136, 59.11779] },
        { y: -3, x: 3, anchor: 'begin', name: 'ЗАО «Тихвинский вагоностроительный завод (ТВСЗ)»', location: 'Ленинградская обл., г. Тихвин', cityShort: 'Тихвин', cityShortEn: 'Tikhvin', geo: [33.542096, 59.644209] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «ТАНН Невский»', location: 'Псковская обл., г. Псков', cityShort: 'Псков', cityShortEn: 'Pskov', geo: [28.332451, 57.819274] },
        { y: -3, x: -3, anchor: 'end', name: 'Гагаринский фанерный завод', location: 'Смоленская обл., г. Гагарин', cityShort: 'Гагарин', cityShortEn: 'Gagarin', geo: [34.996845, 55.553299] },
        { y: -3, x: -3, anchor: 'end', name: 'ООО «ЭГГЕР ДРЕВПРОДУКТ ГАГАРИН»', location: 'Смоленская обл., г. Гагарин', cityShort: 'Гагарин', cityShortEn: 'Gagarin', geo: [34.996845, 55.553299] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Нестле Россия» филиал', location: 'г. Вологда', cityShort: 'Вологда', cityShortEn: 'Vologda', geo: [39.891523, 59.220496] },
        { y: -3, x: 3, anchor: 'begin', name: 'ОАО «Тяжмаш»', location: 'Ленинградская обл., г. Тихвин', cityShort: 'Тихвин', cityShortEn: 'Tikhvin', geo: [33.542096, 59.644209] },
        { y: -3, x: -3, anchor: 'end', name: 'Филиал ОАО «Группа «ИЛИМ»', location: 'Архангельская обл., г. Коряжма', cityShort: 'Коряжма', cityShortEn: 'Koryazhma', geo: [47.159855, 61.315335] },
        { y: -3, x: 3, anchor: 'begin', name: 'ОАО «Архангельский ЦБК»', location: 'Архангельская обл., г. Новодвинск', cityShort: 'Новодвинск', cityShortEn: 'Novodvinsk', geo: [40.820821, 64.413683] },
        { y: -3, x: 3, anchor: 'begin', name: 'ОАО «Монди Сыктывкарский ЛПК»', location: 'Республика Коми, г. Сыктывкар', cityShort: 'Сыктывкар', cityShortEn: 'Syktyvkar', geo: [50.836497, 61.668793] },
        { y: -3, x: 3, anchor: 'begin', name: 'ОАО «Вольскцемент», ООО «Холсим»', location: 'Саратовская обл., г. Вольск', cityShort: 'Вольск', cityShortEn: 'Volsk', geo: [47.38729, 52.045978] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Тобольск-Полимер»', location: 'Тюменская обл., г. Тобольск', cityShort: 'Тобольск', cityShortEn: 'Tobolsk', geo: [68.253762, 58.201698] },
        { y: -3, x: -3, anchor: 'end', name: 'Филиал ОАО «Группа «ИЛИМ»', location: 'Иркутская обл., г. Братск', cityShort: 'Братск', cityShortEn: 'Bratsk', geo: [101.633505, 56.151682] },
        { y: -3, x: -3, anchor: 'end', name: 'Филиал ОАО «Группа «ИЛИМ»', location: 'Иркутская обл., г. Усть-Илимск', cityShort: 'Усть-Илимск', cityShortEn: 'Ust-Ilimsk', geo: [102.741532, 57.943044] },
        { y: -3, x: 3, anchor: 'begin', name: 'Объект «Полярная ГТЭС 150МВТ»', location: 'Красноярский край, Ванкорское месторождение', cityShort: 'Ванкорское месторождение', cityShortEn: 'Vankor Field', geo: [83.552924, 67.801191] },
        { y: -3, x: 3, anchor: 'begin', name: 'АО «Сегежский ЦБК»', location: 'Республика Карелия, г. Сегежа', cityShort: 'Сегежа', cityShortEn: 'Segezha', geo: [34.312617, 63.743705] },
        { y: -3, x: 3, anchor: 'begin', name: 'Группа компаний «Содружество», ООО «Курскагротерминал» Маслоэкстракционный завод (МЭЗ)', location: 'Курская обл.,  Касторенский р-он, село Красная Долина', cityShort: 'Красная Долина', cityShortEn: 'Krasnaya Dolina', geo: [38.090993, 51.722802] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «ЭР ЛИКИД» («AIR LIQUIDE»)', location: 'Нижегородская обл., г. Кстово', cityShort: 'Кстово', cityShortEn: 'Kstovo', geo: [44.206751, 56.150675] },
        { y: -3, x: -3, anchor: 'end', name: 'ОЭЗ «Калуга»', location: 'Калужская обл., Людиновский р-он, ОЭЗ «Калуга»', cityShort: 'ОЭЗ «Калуга»', cityShortEn: '"Kaluga" SEZ', geo: [34.520577, 53.817068] },
        { y: -3, x: 3, anchor: 'begin', name: 'ООО «Каргилл Новоаннинский»', location: 'Волгоградская обл., г. Новоаннинский', cityShort: 'Новоаннинский', cityShortEn: 'Novoanninsky', geo: [42.666670, 50.529658] },

    ];

    function mergeArray(array) {
        let ret = array.reduce((accumulator, cur) => {
            let found = accumulator.find((elem) => elem.cityShort === cur.cityShort);
            if (found) found.name = [found.name, cur.name];
            else {
                cur.name = [cur.name];
                accumulator.push(cur);
            }
            return accumulator;
        }, []);
        return ret;
    }

    return (
        <div className={'map'}>
            <ComposableMap
                height={500}
                data-tip=""
                projection="geoMercator"
                projectionConfig={{
                    rotate: [-60, -50, 0],
                    scale: 1120,
                    center: [2.9, 4],
                }}
            >
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map((geo, index) => (
                            <Geography
                                key={index}
                                geography={geo}
                                className={'map-fix'}
                                style={{
                                    default: {
                                        fill: '#EAEAEC',
                                        stroke: '#D6D6DA',
                                    },
                                    hover: {
                                        fill: '#ededed',
                                        stroke: '#D6D6DA',
                                    },
                                    pressed: {
                                        fill: '#ededed',
                                        stroke: '#D6D6DA',
                                        outline: 'none',
                                    },
                                }}
                            />
                        ))
                    }
                </Geographies>
                {mergeArray(markers).map(({ name, geo, cityShort, cityShortEn, location, y, x, anchor }, index) => (
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover} key={index}>
                        <Marker
                            className={'map-pin'}
                            coordinates={geo}
                            onClick={() => {
                                setPopoverContent({ name: name, location: location });
                            }}
                        >
                            <circle className={'map-circle'} r={3} fill="rgb(52, 133, 120)" stroke="#fff" strokeWidth={1} />
                            {
                                <text x={x} style={{ fontSize: '.5em', fill: '#5D5A6D' }} textAnchor={anchor} y={y}>
                                    {lang === 'ru' ? cityShort : cityShortEn}
                                </text>
                            }
                        </Marker>
                    </OverlayTrigger>
                ))}
            </ComposableMap>
        </div>
    );
});

export default memo(MapChart);
