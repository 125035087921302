import React from 'react';
import Cell from './Cell';
import './honeycomb.scss';

function Honeycomb() {

    const cells = [
        {
            empty: true
        },
        {
            empty: true
        },
        {
            order: 10,
            secondary: true,
            name: 'Услуги генерального подрядчика. Управление проектами',
            nameEn: 'General contractor services. Project Management',
            img: 'welding',
            href: 'service/management'
        },
        {
            empty: true
        },
        {
            empty: true
        },
        {
            order: 8,
            name: `Монтаж электро-оборудования высокого, среднего и низкого напряжения`,
            secondary: true,
            nameEn: 'Installation of high, medium and low voltage electrical equipment',
            img: 'generic1',
            href: 'service/electric'
        },
        {
            order: 9,
            name: 'Проектирование',
            secondary: true,
            nameEn: 'Engineering design',
            img: 'crane',
            company: true,
            href: 'service/design'
        },
        {
            order: 2,
            name: 'Проекты',
            nameEn: 'Projects',
            img: 'mechanics',
            href: 'projects'
        },
        {
            order: 11,
            secondary: true,
            name: 'Сопровождение и согласование документации',
            nameEn: 'Support and approval of documentation',
            img: 'hands',
            href: 'service/support'
        },
        {
            order: 12,
            secondary: true,
            name: 'Монтаж технологического оборудования. Монтаж энергетического оборудования',
            nameEn: 'Installation of technological equipment. Installation of power equipment',
            href: 'service/equipment'
        },
        {
            order: 19,
            secondary: true,
            name: 'Монтаж систем промышленной автоматизации АСУ ТП, КИПиА',
            nameEn: 'Installation of industrial automation systems APCS, I&C',
            img: 'lab',
            href: 'service/automation'
        },
        {
            order: 1,
            name: 'Монтаж слаботочных систем',
            nameEn: 'Installation of low voltage systems',
            img: 'generic2',
            company: true,
            href: 'service/low-voltage'
        },
        {
            main: true,
            company: true
        },
        {
            order: 3,
            name: `Монтаж технологических\u00A0и энергетических трубопроводов`,
            nameEn: 'Installation of process and energy pipelines',
            img: 'clouds',
            company: true,
            href: 'service/energy-pipelines'
        },
        {
            order: 13,
            secondary: true,
            name: 'Монтаж металло-конструкций зданий и сооружений, емкостного оборудования',
            nameEn: 'Installation of metal structures of buildings and structures, capacitive equipment',
            href: 'service/metal-structures'
        },
        {
            order: 18,
            secondary: true,
            name: 'Пусконаладочные работы',
            nameEn: 'Pre-commissioning',
            img: 'warehouse',
            href: 'service/adjustment-works'
        },
        {
            order: 6,
            name: 'Послегарантийное обслуживание',
            nameEn: 'Post-warranty service',
            img: 'computers',
            href: 'service/post-warranty'
        },
        {
            order: 5,
            name: 'Огнезащита конструкций, зданий, сооружений. Анткоррозийная защита',
            nameEn: 'Fire protection for constuctions. Anticorrosive protection',
            img: 'circuit',
            href: 'service/fire-protection'
        },
        {
            order: 4,
            name: 'Сварочное производство',
            nameEn: 'Welding',
            img: 'switchbox',
            href: 'service/welding'
        },
        {
            order: 14,
            secondary: true,
            name: 'Изоляционные работы',
            nameEn: 'Insulation work',
            href: 'service/insulation-works'
        },
        {
            empty: true
        },
        {
            order: 17,
            secondary: true,
            name: 'Электротехническая лаборатория (ЭТЛ)',
            nameEn: 'Electrotechnical laboratory',
            href: 'service/cert'
        },
        {
            order: 16,
            secondary: true,
            name: 'Поставка оборудования и материалов',
            nameEn: 'Supply of equipment and materials',
            img: 'dev',
            href: 'service/supply'
        },
        {
            order: 15,
            secondary: true,
            name: 'Лаборатория неразрушающего контроля качества сварных соеденений (ЛНК)',
            nameEn: 'Laboratory of non-destructive quality control of welded joints',
            href: 'service/lab'
        },
        {
            empty: true
        },
    ]

    return (
        <>
            <div>
                {/* Зачем нужно className="hex-grid" */}
                <div className={'hex-grid__list'}>
                    {cells.map((cell, index) => {
                        return <Cell empty={cell.empty}
                        key={index}
                        order={cell.order}
                        name={cell.name}
                        nameEn={cell.nameEn}
                        smallLogo={cell.smallLogo}
                        img={cell.img}
                        href={cell.href}
                        main={cell.main}
                        secondary={cell.secondary}/>
                    })}
                </div>
            </div>
        </>
    );
}

export default Honeycomb;
