import React from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import AppStore from '../../stores/AppStore';

function Personal(props) {
    let { lang } = AppStore;

    const str1 = lang === 'ru' ? `Нажимая кнопку «${props.action}», я принимаю условия ` : `By pressing the '${props.action}' button, I agree to the `;
    const str2 = lang === 'ru' ? 'соглашения на обработку персональных данных' : 'Terms of processing of my personal data';
    return <Form.Group>
        <Form.Check
            defaultChecked={true}
            required
            label={
                <div>
                    <span>{str1}</span>
                    <Link to='/company/contract' target='_blank'>{str2}</Link>
                    <span>.</span>
                </div>}
        />
    </Form.Group>
}

export default Personal;