import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Form } from 'react-bootstrap';
import Personal from './Personal';
import AppStore from '../../stores/AppStore';

import { apiRequest } from '../../deepApi';

import ym from 'react-yandex-metrika';

function Order(props) {
    const [name, setName] = useState('');
    const [customerCompany, setCustomerCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isSuccess, setIsSuccess] = useState(undefined);
    const [validated, setValidated] = useState(false);

    let { company, lang } = AppStore;

    function handleShow() {
        setIsShown(!isShown);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            request();
        }

        setValidated(true);
    };

    function request() {
        var req = {
            ServiceType: 'заказ',
            ServiceCategory: props.service,
            PreOrderComment: comment,
            CustomerName: name,
            CustomerCompany: customerCompany,
            CustomerEMail: email,
            CustomerTelephone: phone,
        };
        setIsLoading(true);
        apiRequest(req, 'api-cms-preorder-set', requestCallback);
        //ym('reachGoal','ses-mon-click');
    }

    function requestCallback(res) {
        // Добавить проверку на код ответа
        if (res.StatusID >= 0) {
            setIsSuccess(true);
        } else {
            alert('Произошла ошибка');
        }
        setIsLoading(false);
    }

    const action = lang === 'ru' ? 'Заказать' : 'Place order';

    const required = lang === 'ru' ? 'Обязательное поле' : 'Required';

    return (
        <>
            <div style={{width: 100+'%'}}>
                <Card>
                    <Card.Header>{lang === 'ru' ? 'Подготовка заказа' : 'Make order'}</Card.Header>
                    <Card.Body>
                        {lang === 'ru' ? (
                            <Card.Text>
                                Заказать услуги можно через наш Центральный офис по телефону <a href={`tel:${company.CompanyPhone}`}>{company.CompanyPhone}</a> или нажав на кнопку &laquo;{action}&raquo;, через специальную форму подготовки заказа. Наш специалист свяжется для консультации и ответит на все интересующие вас вопросы.
                            </Card.Text>
                        ) : (
                            <Card.Text>
                                You can order services through our Central Office by phone <a href={`tel:${company.CompanyPhone}`}>{company.CompanyPhone}</a> or by clicking on a button &laquo;{action}&raquo;, using an order request form. Our specialist will contact you for consultation and answer all of your questions.
                            </Card.Text>
                        )}
                        <Button variant="primary" onClick={handleShow}>
                            {action}
                        </Button>
                    </Card.Body>
                </Card>
            </div>

            <Modal show={isShown} onHide={handleShow} centered>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{lang === 'ru' ? 'Заказать:' : 'Order:'} {props.service}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!!isSuccess && <Alert variant="success">Спасибо за заказ, {name}!</Alert>}
                        <Form.Group>
                            <Form.Label>{lang === 'ru' ? 'Как к вам обращаться' : 'Your name'}</Form.Label>
                            <Form.Control type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} disabled={isSuccess} required />
                            <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{lang === 'ru' ? 'Название организации' : 'Company name'}</Form.Label>
                            <Form.Control type="text" className="form-control" value={customerCompany} onChange={(e) => setCustomerCompany(e.target.value)} disabled={isSuccess} required />
                            <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{lang === 'ru' ? 'Ваш телефон' : 'Contact phone'}</Form.Label>
                            <Form.Control type="phone" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} disabled={isSuccess} required />
                            <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{lang === 'ru' ? 'Электронная почта' : 'Contact e-mail'}</Form.Label>
                            <Form.Control type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} disabled={isSuccess} required />
                            <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{lang === 'ru' ? 'Комментарий к заказу' : 'Comment'}</Form.Label>
                            <Form.Control as="textarea" rows="5" className="form-control" value={comment} onChange={(e) => setComment(e.target.value)} disabled={isSuccess} required />
                            <Form.Control.Feedback type="invalid">{required}</Form.Control.Feedback>
                        </Form.Group>
                        <Personal action={action} />
                    </Modal.Body>
                    <Modal.Footer>
                        {!!isLoading && <Spinner animation="border" size="sm" />}
                        <Button variant="primary" type="submit" disabled={isLoading || isSuccess}>
                            {action}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default Order;
